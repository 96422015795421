import React from 'react';

const Services = () => {
  const services = [
    {
      title: 'Fly/Mosquito Program',
      description: 'Mosquitoes are potential vectors of diseases such as Malaria, typhoid, and dysentery.',
      icon: '🦟',
    },
    {
      title: 'Louse/Lice/Bed Mites Program',
      description: 'Eradicate at least 27 known pathogens through mattress steam cleaning and treatment against bed mites.',
      icon: '🛏️',
    },
    {
      title: 'Cockroach Infestation Program',
      description: 'Cockroaches are potential vectors of diseases such as Dysentery, Gastroenteritis, and Typhoid.',
      icon: '🪳',
    },
    {
      title: 'Ants Infestation Program',
      description: 'Ants contaminate foodstuffs, spread germs, and speed up spoilage in homes and food handling areas.',
      icon: '🐜',
    },
    {
      title: 'Rodent Infestation Program',
      description: 'Prevent rodent-borne diseases such as Murine typhus fever, Bubonic plague, and Leptospirosis.',
      icon: '🐀',
    },
    {
      title: 'Disinfection of Air Conditioning Intake Filters',
      description: 'Prevent germs, bacteria, and microorganisms in air filters and ducts, including Legionella and Pneumonia.',
      icon: '❄️',
    },
    {
      title: '5 Months Certification',
      description: 'Our treatment against Cockroach/Insect infestation and Deratting is essential every 3 months.',
      icon: '📜',
    },
    {
      title: 'Equipment and Products',
      description: 'We provide all non-toxic pesticides, disinfectants, and necessary equipment for ongoing treatments.',
      icon: '🔧',
    },
    {
      title: 'Non-Toxic Insecticides',
      description: 'Our insecticides are non-toxic to humans, animals, and plants, and are environmentally acceptable.',
      icon: '🌱',
    },
  ];

  return (
    <div className="bg-blue-50 px-10 md:px-0  py-[100px]">
      <div className="text-center mb-10">
        <h2 className="text-green-500 uppercase tracking-wide">Services</h2>
        <h1 className="text-3xl font-bold mt-2">Our Key Objectives and Services</h1>
       
      </div>
      <div className="flex justify-center flex-wrap gap-6">
        {services.map((service, index) => (
          <div key={index} className="bg-white shadow-lg rounded-lg max-w-sm p-6">
            <div className="flex items-center mb-4">
              <span className="text-green-500 text-3xl">{service.icon}</span>
              <h3 className="text-xl font-bold ml-4">{service.title}</h3>
            </div>
            <p className="text-gray-600 mb-4">{service.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
