import React, { useState } from 'react';
import data from '../data/data';

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    inquiryType: '',
    companyName: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://bealsgkoservices.com.ng/api/', { // Ensure this matches your backend URL
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      console.log(response)

      if (response.ok) {
        alert('Your message has been sent successfully!');
        setFormData({
          name: '',
          email: '',
          phone: '',
          inquiryType: '',
          companyName: '',
          message: ''
        });
      } else {
        alert('There was an issue sending your message. Please try again.');
      }
    } catch (error) {
      alert('An error occurred while sending your message. Please try again.');
    }
  };

  return (
    <div className="bg-blue-100 py-12" id='contact'>
      <div className="container mx-auto px-4">
        {/* Contact Information */}
        <div className="text-center mb-10">   
        <h2 className="text-2xl font-bold text-green-600">Contact</h2>

          <p className="text-gray-700 mt-4">
            We are here to help you with any questions or concerns. Feel free to reach out to us!
          </p>
        </div>

        <div className="flex flex-col md:flex-row justify-center gap-8">
          <div className="bg-white shadow-lg rounded-lg p-6">
            <h2 className="text-2xl font-bold text-green-600">Contact Information</h2>
            
            <p className="text-gray-700 mt-4">
              <strong>BEALS GKO SERVICES LIMITED</strong><br />
              Company CAC of Nigeria Registry RC 1263112<br />
              No. 33 Nsirim Street, Off Ikwerre Road<br />
              Beside City Express Hotel Mile 4, Rumueme<br />
              Port Harcourt, Rivers State, NIGERIA
            </p>
            <p className="text-gray-700 mt-4">
              <strong>Phone Numbers:</strong><br />
              <a href="tel:+2348093013357" className="text-blue-500">+2348093013357</a> 
            </p>
            <p className="text-gray-700 mt-4">
              <strong>Email:</strong><br />
              <a href={['mailto:' + data.email]} className="text-blue-500">{data.email}</a><br />
              <a href={['mailto:' + data.secondEmail]} className="text-blue-500">{data.secondEmail}</a><br />
              
             </p>
          </div>

          {/* Contact Form */}
          <div className="bg-white shadow-lg rounded-lg p-6 w-full md:w-1/2">
            <h2 className="text-2xl font-bold text-green-600">Get in Touch</h2>
            <form className="mt-4" onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700">Name</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-600"
                  placeholder="Your Name"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Email</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-600"
                  placeholder="Your Email"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Phone Number</label>
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-600"
                  placeholder="Your Phone Number"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Inquiry Type</label>
                <select
                  name="inquiryType"
                  value={formData.inquiryType}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-600"
                  required
                >
                  <option value="">Select Inquiry Type</option>
                  <option value="Pest Control">Pest Control</option>
                  <option value="Disinfection">Disinfection</option>
                  <option value="General Inquiry">General Inquiry</option>
                </select>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Company Name</label>
                <input
                  type="text"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-600"
                  placeholder="Your Company Name (optional)"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Message</label>
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-600"
                  placeholder="Your Message"
                  rows="4"
                  required
                ></textarea>
              </div>
              <button
                type="submit"
                className="w-full bg-green-600 text-white py-2 rounded-lg hover:bg-green-700 transition duration-300"
              >
                Send Message
              </button>
            </form>
          </div>
        </div>

        {/* Map Section */}
        <div className="mt-12">
           <div className="mt-4 flex justify-center">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3939.9710173613245!2d7.0132379746641!3d4.808606543413439!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1069cdb2b5c5b1c3%3A0xd6bf9bbd6b82c03!2sNsirim%20Street%2C%20Port%20Harcourt!5e0!3m2!1sen!2sng!4v1683047172907!5m2!1sen!2sng"
              width="100%"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Google Map"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
