import React from 'react';
import { Facebook, Twitter, Instagram, Linkedin } from 'lucide-react';
import { Link } from 'react-router-dom';
const Footer = () => {
    return (
        <footer className="bg-gray-900 text-gray-300 py-8">
            <div className="container mx-auto px-4">
                <div className="flex flex-col md:flex-row justify-between items-center">
                    {/* Logo and Description */}
                    <div className="mb-6 md:mb-0 text-center md:text-left">
                        <h2 className="text-3xl font-bold text-white">BEALS GKO SERVICES LIMITED</h2>

                    </div>

                    <div className="flex flex-col md:flex-row gap-8 mb-6 md:mb-0">
                        <div className="text-center md:text-left">
                            <h3 className="text-lg font-semibold text-white mb-2">Quick Links</h3>
                            <ul>
                                <li><Link to="/" className="text-white">Home</Link></li>
                                <li><Link to="/about" className="text-white">About us</Link></li>
                                <li><Link to="/project" className="text-white">What We Do</Link></li>
                                <li><Link to="/contact" className="text-white">Contact us</Link></li>
                            </ul>
                        </div>

                    </div>


                </div>

                <div className="mt-8 border-t border-gray-700 pt-4 text-center">
                    <p className="text-sm text-gray-500">&copy; {new Date().getFullYear()} Robinson Studio. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
