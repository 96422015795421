import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import logo from '../assets/logo.png';
import data from '../data/data';
import { Phone, Mail, Menu, X } from 'lucide-react';

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <header>
            {/* Top Bar */}
            <div className="bg-blue-900 text-white">
                <div className="container mx-auto flex flex-col md:flex-row justify-between items-center py-2 px-4">
                    <div className="flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-4">
                        <div className="flex items-center">
                            <Phone className="mr-2" size={16} />
                            <span>{data.phone}</span>
                        </div>
                        <div className="flex items-center">
                            <Mail className="mr-2" size={16} />
                            <span>{data.email}</span>
                        </div>
                    </div>
                </div>
            </div>

            {/* Main Navigation */}
            <div className="bg-white shadow-md">
                <div className="container mx-auto flex flex-col md:flex-row justify-between items-center py-4 px-4">
                    <div className="flex justify-between items-center w-full md:w-auto">
                        <div className="flex items-center space-x-4">
                            <img src={logo} alt="Logo" className="h-10" />
                            <span className="text-2xl font-bold text-gray-800">{data.siteName}</span>
                        </div>
                        {/* Mobile Menu Button */}
                        <div className="md:hidden">
                            <button
                                className="focus:outline-none"
                                onClick={toggleMenu}
                            >
                                {isMenuOpen ? <X size={24} className="text-gray-800" /> : <Menu size={24} className="text-gray-800" />}
                            </button>
                        </div>
                    </div>

                    {/* Navigation Menu */}
                    <nav className={`${isMenuOpen ? 'grid' : 'hidden'} md:flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-8 mt-4 md:mt-0 w-full md:w-auto`}>
                        <Link onClick={toggleMenu} to="/" className="text-green-500">Home</Link>
                        <Link onClick={toggleMenu} to="/about" className="text-gray-800">About us</Link>
                        <Link onClick={toggleMenu} to="/project" className="text-gray-800">What We Do</Link>
                        <Link onClick={toggleMenu} to="/contact" className="text-gray-800">Contact us</Link>
                    </nav>

                    <div className={`${isMenuOpen ? 'block' : 'hidden'} md:block mt-4 md:mt-0`}>
                        <Link onClick={toggleMenu} to="/contact" className="bg-green-500 text-white py-2 px-4 rounded">Get an Estimate</Link>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
