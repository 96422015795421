export default {
    siteName: "BEALS GKO SERVICES",
    logoLink: "/home",
    logoSrc: "../logo.png",
    phone: "+2348093013357",
    email: "info@bealsgkoservices.com.ng",
    secondEmail: "bealsgko@gmail.com"
};

export const premiumServiceData = {
    heading: "PREMIUM SERVICES",
    title: "Expert Pest Control for Onshore and Offshore Needs",
    description: "With over 12 years of experience, Beals GKO Services delivers comprehensive non-toxic pest control and disinfection programs tailored for both onshore and offshore environments. Our commitment to safety and effectiveness ensures the highest standards of service wherever you are.",
    buttonText: "Discover more",
    imageAlt: "Pest Control"
};

export const aboutData = {
    title: "ABOUT US",
    heading: "Safe, Non-Toxic Pest Control and Disinfection Experts",
    description: "At Beals GKO Services, we take pride in our thorough and safe non-toxic pest control and disinfection programs. We use only non-carcinogenic products to deliver excellent results. With over 9 years of experience, we serve both onshore and offshore locations, including Nigeria, Cameroon, Gabon, Congo, Angola, Singapore, and South Africa.",
    features: [
        "Over 12 Years of Experience",
        "Non-Toxic, Environmentally Friendly Solutions",
        "Certified and Approved by DPR",
        "Comprehensive Pest Control Programs",
        "Effective Disinfection Services",
        "Qualified and Experienced Staff",
        "Global Service Reach"
    ],
    buttonLabel: "Learn more",
    image: "about-image.png"
};
