import { useState, useEffect } from "react";
import pestControlImage from '../assets/about-image.png';
import npma from '../assets/npma.png';
import who from '../assets/who.png';
import pecan from '../assets/pecan.png';
import ontario from '../assets/ontario.png';
import duns from '../assets/duns.png';
import aff from '../assets/aff.png';

export default () => {
    const [yearsSinceStart, setYearsSinceStart] = useState(0);

    const calculateYearsSinceStart = () => {
        const startYear = 2015;
        const currentYear = new Date().getFullYear();
        return currentYear - startYear;
    };

    useEffect(() => {
        setYearsSinceStart(calculateYearsSinceStart());
    }, []);

    return (
        <>
            <section className="py-16 px-5 lg:py-[50px] lg:px-0 md:py-28">
                <div className="max-w-screen-xl mx-auto text-gray-600 gap-x-6 md:gap-x-12 items-center justify-between overflow-hidden flex flex-col md:flex-row md:px-8">
                    <div className="flex-1 space-y-5 md:max-w-lg md:px-0 lg:max-w-xl">
                        <h1 className="text-sm text-indigo-600 font-medium">
                            Over {yearsSinceStart} years of Expertise in Non-Toxic Pest Control
                        </h1>
                        <h2 className="text-2xl md:text-4xl lg:text-4xl text-gray-800 font-extrabold">
                            Bridging Onshore and Offshore Pest Control Solutions
                        </h2>
                        <p className="text-base md:text-lg">
                            At BEALS GKO Services Ltd, we specialize in non-toxic pest control, heat treatment, and disinfection services for both onshore and offshore facilities. With over nine years of experience, we are committed to providing safe and effective integrated pest management solutions tailored to your needs.
                        </p>

                        <div className="flex flex-col sm:flex-row sm:items-center sm:gap-x-3 space-y-3 sm:space-y-0">
                            <a href="#contact" className="block py-2 px-4 text-center text-white font-medium bg-indigo-600 duration-150 hover:bg-indigo-500 active:bg-indigo-700 rounded-lg shadow-lg hover:shadow-none">
                                Contact Us
                            </a>
                            <a href="#about" className="flex items-center justify-center gap-x-2 py-2 px-4 text-gray-700 hover:text-gray-500 font-medium duration-150 active:bg-gray-100 border rounded-lg">
                                Learn More
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                    <path fillRule="evenodd" d="M2 10a.75.75 0 01.75-.75h12.59l-2.1-1.95a.75.75 0 111.02-1.1l3.5 3.25a.75.75 0 010 1.1l-3.5 3.25a.75.75 0 11-1.02-1.1l2.1-1.95H2.75A.75.75 0 012 10z" clipRule="evenodd" />
                                </svg>
                            </a>
                        </div>
                    </div>
                    <div className="flex-none mt-8 md:mt-0 md:max-w-xl">
                        <img
                            src={pestControlImage}
                            className="w-full rounded-tl-[108px] rounded-br-[108px] object-cover"
                            alt="Non-Toxic Pest Control"
                        />
                    </div>
                </div>
                <div className="py-14">
                    <div className="max-w-screen-xl mx-auto px-4 md:px-8">
                        <h3 className="font-semibold text-sm text-gray-600 text-center">
                            Recognised by
                        </h3>
                        <div className="mt-6">
                            <ul className="flex gap-y-6 flex-wrap items-center justify-center [&>*]:px-12 lg:divide-x">

                                <li className="flex-none">
                                    <img src={who} width={100} alt="" />

                                </li>    {/* LOGO 1 */}

                                {/* LOGO 2 */}
                                <li className="flex-none">
                                    <img src={pecan} width={100} alt="" />

                                </li>

                                {/* LOGO 3 */}
                                <li className="flex-none">
                                    <img src={npma} width={100} alt="" />
                                </li>


                                {/* LOGO 3 */}
                                <li className="flex-none">
                                    <img src={ontario} width={200} alt="" />

                                </li>


                                {/* LOGO 3 */}
                                <li className="flex-none">
                                    <img src={aff} width={200} alt="" />

                                </li>
                                {/* LOGO 3 */}
                                <li className="flex-none">
                                    <img src={duns} width={200} alt="" />

                                </li>


                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
