import React from 'react';
import { aboutData } from '../data/data';
import aboutImage from '../assets/banner.png';

const About = () => {
    return (
        <div id='about'>

            <section className="relative bg-blue-100  py-12 lg:py-[100px]">

                <div className="container mx-auto flex flex-col md:flex-row items-center px-6 md:px-12 gap-10">
                    <div className="md:w-1/2 mt-1 p-5 md:mt-0  md:flex relative">
                        <img src={aboutImage} alt="About Us" className="w-full  rounded-[108px] shadow-lg" />
                    </div>

                    <div className="md:w-1/2 text-center md:text-left mt-8 md:mt-0">
                        <h4 className="text-green-500 font-medium">{aboutData.title}</h4>
                        <h1 className="text-2xl md:text-2xl font-bold text-gray-800 mt-2">
                            {aboutData.heading}
                        </h1>
                        <p className="text-gray-600 mt-4 mb-5 px-2">
                            {aboutData.description}
                        </p>
                        <ul className="mt-5">
                            {aboutData.features.map((feature, index) => (
                                <li key={index} className="text-gray-600 flex items-center mb-2">
                                    <span className="text-green-500 mr-2">✔</span> {feature}
                                </li>
                            ))}
                        </ul>
                        
                    </div>
                </div>

            </section>
        </div>
    );
};

export default About;
