import React from 'react';

const WhyChooseUs = () => {
  return (
    <section className="bg-white px-5 md:px-12 pb-16">
      <div className="text-center">
        <h3 className="text-black text-sm uppercase pt-[2rem] font-semibold mb-2">
          Why Choose Us
        </h3>
        <h2 className="text-2xl font-bold text-white mb-12">
          OUR MISSION, VISION AND GOAL
        </h2>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-3 gap-8">

        <div className="text-center h-52">
          <div className="bg-blue-900 p-6 rounded-md mx-auto py-5 mb-4 h-full flex flex-col justify-center">
            <h3 className="text-lg font-bold pb-2 text-white mb-2">
              Mission Statement
            </h3>
            <p className="text-white pb-5">
              Our mission is to deliver high-quality, safe, and standard non-toxic pest control services and integrated pest management solutions. We are committed to serving both the public and private sectors with innovative, effective, and environmentally responsible approaches to pest control, ensuring a healthier and more comfortable environment for all.
            </p>
          </div>
        </div>

        <div className="text-center h-52">
          <div className="bg-blue-900 p-6 rounded-md mx-auto mb-4 h-full flex flex-col justify-center">
            <h3 className="text-lg font-bold pb-2 text-white mb-2">
              Vision Statement
            </h3>
            <p className="text-white pb-5">
              To add value and excellence in the Nigerian business environment through innovations and reinvention of our services in pest control management
            </p>
          </div>
        </div>

        <div className="text-center h-52">
          <div className="bg-blue-900 p-6 rounded-md mx-auto mb-4 h-full flex flex-col justify-center">
            <h3 className="text-lg font-bold pb-2 text-white mb-2">
              Our Goal
            </h3>
            <p className="text-white pb-5">
              To develop and advance a highly efficient company that leads the industry in implementing safe, environmentally friendly procedures across all our services.  </p>
          </div>
        </div>

      </div>
    </section>
  );
};

export default WhyChooseUs;
