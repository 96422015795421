import React from 'react';
import Banner from '../components/Banner';
import About from '../components/About';
import Contact from '../components/Contact';
import WhyChooseUs from '../components/WhyChooseUs';
import Services from '../components/Services';

const AboutPage = () => {
  return (
    <>
    <About />
     
    </>
  );
};

export default AboutPage;
