import React from 'react';
import Banner from '../components/Banner';
import About from '../components/About';
import Contact from '../components/Contact';
import WhyChooseUs from '../components/WhyChooseUs';
import Services from '../components/Services';
import Missions from '../components/Missions';

const Home = () => {
  return (
    <>
    <Banner />
    <WhyChooseUs />
    <About />
    <Services />
    <Missions />

    <Contact />
     
    </>
  );
};

export default Home;
