import React from 'react';

const WhyChooseUs = () => {
  return (
    <section className="bg-blue-500 px-5 md:px-12 py-16">
      <div className="text-center">
        <h3 className="text-black text-sm uppercase font-semibold mb-2">
          Why Choose Us
        </h3>
        <h2 className="text-2xl font-bold text-white mb-12">
          We use only non-carcinogenic products for excellent results!
        </h2>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
        
        <div className="text-center h-52">
          <div className="bg-blue-900 p-6 rounded-md mx-auto mb-4 h-full flex flex-col justify-center">
            <h3 className="text-lg font-bold py-2 text-white mb-2">
              Company CAC of Nigeria Registry RC 1263112
            </h3>
            <p className="text-white">
              Specializing in Non-Toxic Pest Control and Disinfection Programs.
            </p>
          </div>
        </div>

        <div className="text-center h-52">
          <div className="bg-blue-900 p-6 rounded-md mx-auto mb-4 h-full flex flex-col justify-center">
            <h3 className="text-lg font-bold py-2 text-white mb-2">
              Onshore and Offshore Services
            </h3>
            <p className="text-white">
              Operating for 12 years in Nigeria, Cameroun, Gabon, Congo, Angola, Singapore, and South Africa.
            </p>
          </div>
        </div>

        <div className="text-center h-52">
          <div className="bg-blue-900 p-6 rounded-md mx-auto mb-4 h-full flex flex-col justify-center">
            <h3 className="text-lg font-bold py-2 text-white mb-2">
              Pest Control and Disinfection Services
            </h3>
            <p className="text-white">
              Including Fly/Mosquito program, Louse/Lice/Bed Mites program, Cockroach infestation program, and more.
            </p>
          </div>
        </div>

        <div className="text-center h-52">
          <div className="bg-blue-900 p-6 rounded-md mx-auto mb-4 h-full flex flex-col justify-center">
            <h3 className="text-lg font-bold py-2 text-white mb-2">
              Certifications and Safety Procedures
            </h3>
            <p className="text-white">
              We provide TRA, SOP, JSA, and HIRA on request, and offer 5 months certification including 1-month treatment.
            </p>
          </div>
        </div>

      </div>
    </section>
  );
};

export default WhyChooseUs;
