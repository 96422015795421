import React from 'react';
import Banner from '../components/Banner';
import About from '../components/About';
import Contact from '../components/Contact';
import WhyChooseUs from '../components/WhyChooseUs';
import Services from '../components/Services';

const ProjectPage = () => {
  return (
    <>
    
    <Services />
     
    </>
  );
};

export default ProjectPage;
